import React,  { Component } from 'react';

import {Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const Footer = () => (
  <FooterClass />
  );

class FooterClass extends Component {

	getYear = () => {
			return new Date().getFullYear()
		}
	
	render() {		
		const fontstyle = {
	      color: "white",
	      fontSize: "10px"
	    };
		
		
		
		return (
			
			<div style={fontstyle}>									
				<Row className="justify-content-center">
					<Col xs={7} md={3}>
						<FontAwesomeIcon icon={faCopyright} /> Copyright {this.getYear()} The Assurance Group, Inc.
					</Col>
				</Row>
				
			</div>
			
		);

	}
}



export default Footer;
import React,  { Component } from 'react';
import { Link } from 'react-router-dom';

import {Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Event} from '../Analytics';

library.add(
  fas
  // more icons go here
);

const NoAccessCode = () => (
  <NoAccessCodeItem />
  );

class NoAccessCodeClass extends Component {
	
	render() {		
		const fontstyle = {
	      color: "white"
	    };
		const DivStyle = {		  
			borderRadius: "25px",
			border: "2px solid #000000",
			background: "rgb(115, 111, 111, 0.75)",
			padding: "20px",
		 };
		
		return (
			
			<div style={DivStyle}>									
				<Row className="justify-content-center">
					<Col>
						<Link style={fontstyle} onClick={() =>Event("ACCESSCODE", "No Access Code submitted", "ACCESSCODE")} to='/upload'>
		 					<FontAwesomeIcon icon={['fas', 'question-circle']} size="lg" /> Click here if you do not have or cannot find your Access Code 
	 					</Link>
					</Col>
				</Row>
				
			</div>
			
		);

	}
}

const NoAccessCodeItem = NoAccessCodeClass;

export default NoAccessCode;
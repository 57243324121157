import React,  { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import {
  BrowserView,
  MobileView

} from "react-device-detect";
import {PageView, initGA} from '../Analytics';

import * as ROUTES from '../../constants/routes';

import Navigation from '../Navigation';
import Home from '../Home';
//import Upload from '../UploadFirebase';
import Upload from '../Upload';
import Footer from '../Footer';


import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';


import VideoBg from "reactjs-videobg";
// import tornadoogg from "../../artifacts/combined.ogg";
// import tornadowebm from "../../artifacts/combined.webm";
import tornadoimage from "../../artifacts/Tornado.webp";


	

const App = () => (
  <Apps />
  );

class AppClass extends Component {

	componentDidMount() {
		initGA(process.env.REACT_APP_GOOGLE_ANALYTICS);
		PageView();
	}

	render() {		

		const backgroundmobile = {	       
	       backgroundImage: `url(${tornadoimage})`,
	       backgroundRepeat: "no-repeat",
	       backgroundAttachment: "fixed",
	       backgroundSize: "cover"
	       
	    };			
	    

		return (
			<Router>
				<BrowserView>
					<div>					
					    <VideoBg poster={tornadoimage}>			 													
						</VideoBg>	
						<Navigation />	   
						<Container>		
							<Route exact path={ROUTES.LANDING} component={Home} />
         					<Route path={ROUTES.UPLOAD} component={Upload} />	      
							<Footer />					          
						</Container>			    
						
					</div>
				</BrowserView>
				<MobileView>
					<div style={backgroundmobile}>
						<Navigation />	   
						<Container fluid>			      
							<Route exact path={ROUTES.LANDING} component={Home} />
         					<Route path={ROUTES.UPLOAD} component={Upload} />			          
         					<Footer />	
						</Container>			    
						
					</div>					    
					
				</MobileView>
									
			</Router>
		);

	}
}

const Apps = AppClass;

export default App;
import React,  { Component } from 'react';


import AccessCode from '../AccessCode';
import NoAccessCode from '../NoAccessCode';
import {PageView, initGA} from '../Analytics';

import {Row, Col} from 'react-bootstrap';


const Home = () => (
	<HomePage />
);

class HomeClass extends Component {
	constuctor() {
    	this.redirectToTarget = this.redirectToTarget.bind(this);
  	}

	redirectToTarget = () => {
		this.props.history.push('upload');		
	}
	componentDidMount() {
	    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS);
	    PageView();
	  }


	render() {	
		
		const fontstyle = {
	      color: "white"
	    };	
		

		return (
			<div style={fontstyle}>
			
				<Row>
					<Col>
						<h1>Welcome to Insured.fyi!</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>A requirement of your loan agreement is to maintain acceptable insurance coverage on the property securing your loan. If you have arrived at this page, we need you to upload your insurance for verification. This site provides an easy and secure method for you to submit insurance coverage to us, your financial institution, for verification.</p>

						<p>Please use one of the options below to complete the verification process.</p>

						<p>If you have received a letter with an Access Code please provide that below.</p>
						<AccessCode />

						<Row>
							<Col>
							&nbsp;
							</Col>
						</Row>
						<Row>
							<Col>
							&nbsp;
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col xs={12}>
								<NoAccessCode />
			 				</Col>			 				
		 				</Row>
	 				</Col>
 				</Row>

			</div>
			
		);

	}
}

const HomePage = HomeClass;

export default Home;
import React,  { Component } from 'react';

import {Row, Col} from 'react-bootstrap';


class UploadDescription extends Component {


  render() {

    const divpadding = {
      padding: "0px 0px 0px 20px"
    }
    
    return (
      <div >
        <Row>
          <Col>
            <h5>Please use the upload form in the lower portion of this page to submit the requested documents. Prior to clicking "Upload", please make sure the documents being uploaded contain the following information:</h5>
          </Col>
        </Row>
         <Row>
          <Col>&nbsp;
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>            
            <strong>Agent Information:</strong>
              <Row>
                <Col>                
                  <div style={divpadding}>
                    Insurance agent’s name<br/>
                    Insurance agent's address<br/>
                    Insurance agent’s telephone number
                  </div>
                </Col>
              </Row>
            <br />
            <strong>Property Information:</strong><br/>
            <Row>
                <Col>                
                  <div style={divpadding}>
                    Property address or vehicle year, make, and model
                  </div>
                </Col>
              </Row>
          </Col>
          <Col xs={12} className="d-md-none">
            &nbsp;
          </Col>
          <Col xs={12} md={6}>
            <strong>Insurance Information:</strong>
            <Row>
                <Col>                
                  <div style={divpadding}>
                Insured’s name and address<br/>
                Insurance company’s name<br/>
                Policy number<br/>
                Effective date of insurance<br/>
                Coverage amount<br/>
                Deductibles<br/>
                Mortgagee, lienholder, or loss payee</div>
                </Col>
              </Row>
          </Col>         
        </Row>          
      </div>
    );
  }
}
export default UploadDescription;
import React,  { Component } from 'react';
import { Redirect } from 'react-router-dom';

import {Button, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation"
import {Event} from '../Analytics';

import LetterIdentifications from '../../artifacts/LetterIdentifications.svg';

const AccessCode = () => (
  <AccessCodeForm />
  );

class AccessCodeClass extends Component {
	constructor(props) {
		super(props);	
		 this.state = {
	      validated:  false,
	      setValidated : false,
	      submit : false,
	      accesscode: "",
	      groupnumber:"",
	      valid:false,
	      ...props
	    };
	};


	isValid = () => {

		let regex = /[0-9]{6}/;		
		if(regex.test(this.state.accesscode)) {
			this.setState({submit : true});
			return true;
		} else {
			return false;
		}
		
	};

	submitHandler = event => {
		event.preventDefault();
		this.setState({submit : true});
		this.setState({valid :true});		
		
	};

	changeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });

	};


	


	render() {		
		const {accesscode, submit, valid} = this.state;
		const DivStyle = {		  
			borderRadius: "25px",
			border: "2px solid #000000",
			background: "rgb(115, 111, 111, 0.75)",
			padding: "20px",
		 };
		 const ImgStyle = {		  
			borderRadius: "0px 0px 5px 5px",			
			overflow:"hidden",
			boxShadow: "10px 10px 10px black"
      
		 };

		if(valid) {
			let path = "/upload/" + accesscode;
			Event("ACCESSCODE", "Access Code submitted", "ACCESSCODE");
			return <Redirect to={path} />
		}

		let submitbutton;
		if(submit) {
			submitbutton = <FontAwesomeIcon icon={faSpinner} spin />;
		} else {
			submitbutton = "Submit";
		}



		return (
			
			<div style={DivStyle}>	
				<Row>
					<Col>
						<p>The Access Code is located in the lower, right corner of the letter we sent you.</p>
					</Col>
				</Row>				
				<Row className="justify-content-center">
					<Col>
						<img style={ImgStyle} src={LetterIdentifications} alt="Letter Layout"/>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col>
						&nbsp;
					</Col>
				</Row>
				<Row>
					<Col>			
						<ValidationForm onSubmit={this.submitHandler}>
							<Row className="form-group justify-content-center">
								<Col xs={5} md={2}>
								<label htmlFor="accesscode">Access Code: </label>
								</Col>
								<Col xs={7} md={3}>
								<TextInput 
									name="accesscode" 
									id="accesscode" 
									onChange={this.changeHandler}
									placeholder="Enter Access Code Here"
									required
									pattern='[0-9]+'
									maxLength="6"
									errorMessage={{
										required: "Please enter a valid Access Code.",
										pattern: "Please enter a valid Access Code.",
										maxLength: "Please enter a valid Access Code."
									}}
								 />
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col xs={4} md={2}>
									<Button variant="primary" type="submit">					
										{submitbutton}
									</Button>
								</Col>
							</Row>
						</ValidationForm>
						
					</Col>
				</Row>	
			</div>
			
		);

	}
}

const AccessCodeForm = AccessCodeClass;

export default AccessCode;
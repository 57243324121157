import React,  { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import "./index.css";

import * as ROUTES from '../../constants/routes';
import Dropzone from "../Dropzone";
import Progress from "../Progress";
import UploadDescription from "../UploadDescription";

import {PageView, initGA, Event} from '../Analytics';

import {
  BrowserView,
  MobileView

} from "react-device-detect";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Button, Row, Col, Table, Popover, OverlayTrigger} from 'react-bootstrap';
import { ValidationForm, TextInput, TextInputGroup } from "react-bootstrap4-form-validation"
import validator from 'validator'

library.add(
  fas
  // more icons go here
);


 
const Upload = () => (
  <div>
    <h1>&nbsp;</h1>
    
    <Switch>     
      <Route exact path={ROUTES.UPLOAD} component={UploadGeneric} />
      <Route exact path={ROUTES.UPLOADACCESSCODE} component={UploadGeneric} />   
    </Switch>
  </div>
);

class UploadGenericClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,            
      MaxSize: 10485760,
      UploadedBy:null,
      UploadedByEmail: null,
      allsuccessful:true

    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    //this.RemoveFile = this.RemoveFile.bind(this);    
  }

  componentDidMount() {
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS);
    PageView();
  }

  RemoveFile = (event) => {
    //alert(event.currentTarget.dataset.id);
    var array = this.state.files;
    
    if (event.currentTarget.dataset.id !== -1) {
      array.splice(event.currentTarget.dataset.id,1);
      this.setState({people: array});
    }
  }

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
     Event("FILESUPLOADED", "Files Submitted", "FILESUPLOADED", this.state.files.length);
    this.state.files.forEach(file => {      
      if (this.FileCheck(file)) {
        promises.push(this.sendRequest(file));
      } else {        
        const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
          state: "error",
          percentage: 0
          };
        this.setState({ uploadProgress: copy });
      }
    });
    try {
      await Promise.all(promises);
      
      this.setState({ successfullUploaded: true, uploading: false, allsuccessful : true });
    } catch (e) {

      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false, allsuccessful : false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
     const req = new XMLHttpRequest();

      if(file.size < this.state.MaxSize) {
         

        req.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
            const copy = { ...this.state.uploadProgress };
            copy[file.name] = {
              state: "pending",
              percentage: (event.loaded / event.total) * 100
            };
            this.setState({ uploadProgress: copy });
          }
        });

        req.upload.addEventListener("load", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy });
          resolve(req.response);
        });

        req.upload.addEventListener("error", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "error", percentage: 0 };
          this.setState({ uploadProgress: copy });
          
          reject(req.response);
        });
        const formData = new FormData();      
        formData.append("UploadedBy", this.state.UploadedBy);
        formData.append("UploadedByEmail", this.state.UploadedByEmail);
        if (this.props.match.params.ac) {
          formData.append("AccessCode", this.props.match.params.ac);  
        }      
                
        formData.append("file", file, file.name);        
        formData.append("ID", process.env.REACT_APP_TAG_API_KEY);  
        req.open("POST", process.env.REACT_APP_API_URL);        
        req.setRequestHeader('TAGAuthorization', 'Bearer ' + process.env.REACT_APP_TAG_API_KEY);
        req.send(formData);

      } else {
         const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "large", percentage: 0 };
          this.setState({ uploadProgress: copy });
          reject(req.response);
      }


      
    });
  }




  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];

    if (this.state.uploading || this.state.successfullUploaded) {

      let results;
      if (uploadProgress && uploadProgress.state ==="error") {
        results = <FontAwesomeIcon icon={['fas', 'exclamation-circle']}  style={{color:"red"}} />
        
        
      } else if (uploadProgress && uploadProgress.state ==="large") {
        results = <FontAwesomeIcon icon={['fas', 'exclamation-circle']}  style={{color:"red"}} />
       
      } else if (uploadProgress && uploadProgress.state ==="done") {
        results = <FontAwesomeIcon icon={['fas', 'check-circle']} style={{color :"green" }} />
        
      } else {        
        results = <FontAwesomeIcon icon={['fas', 'spinner']} spin 
            style={{color:"green",
                opacity:
                  uploadProgress && uploadProgress.percent ? uploadProgress.percentage / 100 : 0
              }}
              />

      }
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} /> {results}          
        </div>
      );
    }
  }

  rendertext = () => {
    

    if(this.state.uploading) {

      return (
        <div>
          <FontAwesomeIcon icon={['fas', 'spinner']} spin /> Uploading 
        </div>
      )      
      
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={['fas', 'upload']} /> Upload 
        </div>
      )      
    }

  }

  renderActions() {
    const fontstyle = {
        background: "#dc3545",
        borderRadius: "10px",
        padding: "5px",
        color: "white",
      };

    if (this.state.successfullUploaded) {
      return (
        <div>
        {!this.state.allsuccessful &&
            <Row className="form-group justify-content-center">
              <Col xs={11} md={11} style={fontstyle}>
                <span >There as an error uploading one or more documents. Please send the documents with an <FontAwesomeIcon icon={['fas', 'exclamation-circle']}  /> to <a href="mailto:insurance@insured.fyi" style={{color: "white"}} rel="noopener noreferrer" target="_blank">insurance@insured.fyi</a> or fax to 256 767-7712</span>
              </Col>
            </Row>
          }
        <Row className="form-group justify-content-center">
          <Col xs={5} md={4}>
            <Button variant="dark"
              onClick={() =>
              this.setState({ files: [], uploadProgress : {}, successfullUploaded: false, uploading : false })
              }
              >
              Reset
            </Button>
          </Col>
        </Row>
        </div>
        );
    }  else {
          let DisableUpload = true;

          if (this.state.files.length < 0 || this.state.uploading || !this.state.UploadedByEmail || !this.state.UploadedBy) {
            DisableUpload = true;
          } else {
            this.state.files.forEach(file => {
              if (this.FileCheck(file)) {
                DisableUpload = false;
              }
            });
          }
         
        
        return (
          <div>
          {DisableUpload &&
            <Row className="form-group justify-content-center">
              <Col xs={11} md={9}>
                <span>Please complete this form before pressing "Upload".</span>
              </Col>
            </Row>
          }
          <Row className="form-group justify-content-center">
            <Col xs={5} md={4}>
              <Button variant="dark"
                disabled={DisableUpload}
                onClick={this.uploadFiles}
                style={{cursor : DisableUpload ? "not-allowed" : "auto"}}
                >
                {this.rendertext()}
              </Button>
            </Col>
          </Row>
          </div>
        );
        
    }
  }

  FileCheck = (file) => {    
    // image/*,application/pdf
    let regex = /image\//;

    if (file.size > this.state.MaxSize) {
      return false;
    } else if ( file.type === 'application/pdf' ||
      regex.test(file.type)) {
      return true;    
    } else {
      return false;
    }   
  }

  SizeCheck = (file) => {
    let value;
    
    let regex = /image\//;

    if (file.size > this.state.MaxSize) {
      value = <div>This file is too large to upload.</div> 
    } else if (file.type === 'application/pdf' ||
      regex.test(file.type)
    ) {
      if ( this.state.uploading || this.state.successfullUploaded) {
          value = <div></div>           
      } else {
      value = <div>Pending Upload...</div>           
      }             
    } else {
      value = <div>Only PDF or Image files are allowed.</div> 
    }
    
    return (
      <div>
        {value}
      </div>
     )
   }
  FormTitle = () => {
    let value;
    if(this.props.match.params.ac) {
      value = <div>Document Upload for Access Code: {this.props.match.params.ac}</div>
    } else {
      value = <div>Document Upload Form:</div>
    }
    return value;
  }

  handleChange = (e) => {
      this.setState({
          [e.target.name]: e.target.value
      })
  }

  render() {
    
    const DivStyle = {      
      borderRadius: "25px",
      border: "2px solid #000000",
      background: "rgb(115, 111, 111, 0.75)",
      padding: "20px",
      color:"white",
     };
     const FileStyle = {      
      backgroundColor: "#fff",
      border: "2px solid rgb(187, 186, 186)",      
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "16px",
     };


    const emailpopover = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Why do you need my email?</Popover.Title>
          <Popover.Content>
            We require your email address in the event more information is needed regarding the document you provided. We will never sell your contact information. Your contact information will only be visible to our internal team and the lienholder/loss payee listed on the document.
          </Popover.Content>
        </Popover>
      );
    
    return (
      <div>
        <Row style={DivStyle} className="justify-content-center">
          <Col >
            <UploadDescription />   
          </Col>
        </Row>
        <Row>
          <Col>      
            &nbsp;   
          </Col>
        </Row>
        <Row style={DivStyle} className="justify-content-center">
          <Col>  
            <Row className="justify-content-center">
              <Col>      
                <h5>{this.FormTitle()}</h5>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <ValidationForm onSubmit={(e, formData, inputs) => { e.preventDefault(); }}>
                  <Row  className="form-group justify-content-center">
                    <Col xs={12} md={1}>
                      <label htmlFor="Name"><strong>Name:</strong></label>                  
                    </Col>
                    <Col xs={12} md={5}>
                      <TextInput
                        name="UploadedBy" 
                        id="UploadedBy" 
                        onChange={this.handleChange}
                        placeholder="Enter Your Name"
                        required                    
                        errorMessage={{
                          required: "Please enter your name.",
                         
                        }}
                     />                
                    </Col>
                  </Row>
                  <Row className="form-group justify-content-center">
                    <Col xs={12} md={1}>                
                      <label htmlFor="email"><strong>Email:</strong></label>
                    </Col>
                    <Col xs={12} md={5}>
                      <TextInputGroup 
                          name="UploadedByEmail" 
                          id="UploadedByEmail" 
                          type="email" 
                          validator={validator.isEmail} 
                          placeholder="Enter Your Email Address"
                          errorMessage={{validator:"Please enter a valid email address"}}                      
                          onChange={this.handleChange}
                          append={<div className="input-group-text"> <BrowserView> <OverlayTrigger trigger="click" placement="right" overlay={emailpopover}><FontAwesomeIcon icon={['fas', 'info-circle']} /></OverlayTrigger></BrowserView><MobileView> <OverlayTrigger trigger="click" placement="left" overlay={emailpopover}><FontAwesomeIcon icon={['fas', 'info-circle']} /></OverlayTrigger></MobileView></div>}
                        /> 
                    
                    </Col>
                  </Row>
                  <Row className="form-group justify-content-center">
                    <Col xs={12} md={6} style={{display : this.state.uploading || this.state.successfullUploaded ? "none" : "block" }}>            
                      <Dropzone
                        onFilesAdded={this.onFilesAdded}
                        disabled={this.state.uploading || this.state.successfullUploaded}
                        
                      />            
                    </Col>
                  </Row>
                  <Row  style={FileStyle} className="form-group justify-content-center">
                    <Col xs={12} >
                      <div className="Files">
                        <Table striped hover>
                              <thead>
                                <tr>
                                  <th>File</th>
                                  <th>Progress</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                          {this.state.files.map((file, index) => {
                            return [                  
                                <tr key={file.name}> 
                                <td >
                                  <span className="Filename">{file.name}</span>
                                 
                                </td>
                                <td>
                                {this.SizeCheck(file)}
                                {this.renderProgress(file)}
                                </td>
                                <td key={file.name} style={{ display: this.state.uploading || this.state.successfullUploaded ? "none" : "block" }}>
                                  <span className="Filename" style={{ cursor: this.state.uploading || this.state.successfullUploaded ? "default" : "pointer" }} ><FontAwesomeIcon icon={['fas', 'trash-alt']} data-id={index} onClick={this.RemoveFile} /></span>
                                </td>
                                </tr>
                            ];
                          })}
                          </tbody>                 
                        </Table>                          
                      </div>
                    </Col>
                  </Row>                                  
                  <Row className="form-group justify-content-center">
                    <Col xs={12} md={6}>
                      <div className="Actions">{this.renderActions()}</div>          
                    </Col>
                  </Row>
                </ValidationForm>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}


const UploadGeneric = UploadGenericClass
export default Upload
import React,  { Component } from 'react';
import { Link } from 'react-router-dom';



import {Navbar} from 'react-bootstrap';

const Navigation = () => (
  <div>    
    <NavigationClass />          
  </div>
);


class NavigationClass extends Component {
	render () {
		const fontstyle = {
	      color: "white"
	    };
		return (
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
				<Navbar.Brand>
					<Link style={fontstyle} to="/">Insured FYI Insurance Verification</Link>
				</Navbar.Brand>
			</Navbar>
		);
	}

}


export default Navigation;



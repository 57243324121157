import React, { Component } from "react";
import {
  BrowserView,
  MobileView

} from "react-device-detect";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Row, Col} from 'react-bootstrap';
import "./index.css";

library.add(
  fas
  // more icons go here
);




class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
  	const DivStyle = {            
      color:"black",
      opacity: "0.5",
      textAlign: "center"
     };
     const ImageSize = {
      opacity: 0.3,     
      color:"black" 
     }
    return (
      <div
        className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
          accept="image/*,application/pdf"
        />
        <FontAwesomeIcon
          alt="upload"
          icon={['fas', 'cloud-upload-alt']}
          size="7x"
          style={ImageSize}        
        />
        <BrowserView>
        <Row style={DivStyle} className="justify-content-center">
          <Col><strong>Drag and drop image or PDF files here for uploading,<br /> or click to select files for uploading<br />(Max Size 10MB)</strong>
          </Col>
          </Row>
        </BrowserView>
        <MobileView>
          
          <Row style={DivStyle} className="justify-content-center">
          <Col><strong>Click here to select files for uploading<br /> or to take a picture of the document.<br />(Max Size 10MB)</strong></Col>
          </Row>
          
        </MobileView>
      </div>
    );
  }
}

export default Dropzone;
